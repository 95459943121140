import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"

import BlogPost from "../../components/BlogPost"
import { Link } from "../../components/Link"
import { SearchResults } from "../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="12 gute Valentinstag Geschenke für Frauen"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Typography paragraph={true}>
            „Was kann ich meiner Freundin zum Valentinstag schenken?“ stellst du dir die Frage diese
            Tage, denn am 14. Februar ist es wieder soweit und es ist Valentinstag. Du willst deiner
            Freundin, Verlobten oder auch deiner Frau nicht irgendein Geschenk machen, sondern das
            perfekte Geschenk für den Valentinstag, mit dem du die Liebste überrascht. Frauen lieben
            Taschen, denn sie sind nicht nur schick, sondern auch praktisch zugleich. Jede Frau
            besitzt mindestens eine{" "}
            <Link href="/handtaschen/" underline="always">
              Handtasche
            </Link>{" "}
            und hat niemals genug davon. Eine{" "}
            <Link href="/marken/aigner/" underline="always">
              aigner
            </Link>{" "}
            Handtasche oder eine doch eher eine{" "}
            <Link href="/marken/love-moschino/" underline="always">
              LOVE MOSCHINO
            </Link>{" "}
            <Link href="/umhaengetaschen/" underline="always">
              Umhängetasche
            </Link>
            ? Wir haben 12 tolle Geschenkideen herausgesucht, die du zum Valentinstag deiner großen
            Liebe schenken kannst.
          </Typography>
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25334427165&a=667803&m=14336",
                images: [
                  {
                    alt:
                      "Ein Bild einer Calvin Klein Henkeltasche »WORKED SML TOTE«, mit praktischer Einteilung",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/f91494e39a7a50496337c965e82527db.jpg",
                    title: "Calvin Klein Henkeltasche mit praktischer Einteilung",
                  },
                ],
                name: "Calvin Klein Henkeltasche",
                price: 149.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25322149551&a=667803&m=14962",
                images: [
                  {
                    alt: "Ein Bild einer Tommy Hilfiger Handtasche 'CORE MED SATCHEL'",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/49489a854e751bc97b1512e2ba562863.jpg",
                    title: "Tommy Hilfiger Handtasche 'CORE MED SATCHEL'",
                  },
                ],
                name: "Tommy Hilfiger Handtasche",
                price: 139,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25153438119&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Michael Kors Umhängetasche Cece Medium in schwarz",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/ad604ca7c1797906c62b7ba016c0c38c.jpg",
                    title: "Michael Kors Umhängetasche Cece Medium schwarz",
                  },
                ],
                name: "Michael Kors Umhängetasche",
                price: 199.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24650778585&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Love Moschino Umhängetasche in rot",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/43f6266c0bdf3230f24864936f70df04.jpg",
                    title: "Love Moschino Umhängetasche rot",
                  },
                ],
                name: "Love Moschino Umhängetasche",
                price: 89.99,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=24011509259&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Marc Jacobs Umhängetasche The Mj in grau",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/8e5ddba26c466fefd3100effd257b8bc.jpg",
                    title: "Marc Jacobs Umhängetasche The Mj grau",
                  },
                ],
                name: "Marc Jacobs Umhängetasche",
                price: 390,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25635733993&a=667803&m=14962",
                images: [
                  {
                    alt: "Ein Bild einer aigner Ivy Handtasche in schwarz",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/49489a854e751bc97b1512e2ba562863.jpg",
                    title: "aigner Ivy Handtasche",
                  },
                ],
                name: "aigner Ivy Handtasche",
                price: 499,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24477120993&a=667803&m=11926",
                images: [
                  {
                    alt:
                      "Ein Bild einer The Bridge Handtasche Vigna Nuova Handtasche 5859 in Cognac",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/ad604ca7c1797906c62b7ba016c0c38c.jpg",
                    title: "The Bridge Handtasche Vigna Nuova Handtasche 5859 Cognac",
                  },
                ],
                name: "The Bridge Handtasche",
                price: 301,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25736248741&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Guess Shopper Digital braun",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/2a094779b6903ac394765cfe7f324718.jpg",
                    title: "Guess Shopper Digital braun",
                  },
                ],
                name: "Guess Shopper",
                price: 139,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25658677569&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Alexander Mcqueen Umhängetasche The Story Small in rot",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/c173cd02f031bb706ed5e21cba2f4fbd.jpg",
                    title: "Alexander Mcqueen Umhängetasche The Story Small rot",
                  },
                ],
                name: "Alexander McQueen Umhängetasche",
                price: 1790,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25625476301&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Jimmy Choo Clutch Varenne in gold",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/2843cb33cca6da48a36dd7c106a9fbc1.jpg",
                    title: "Jimmy Choo Clutch Varenne gold",
                  },
                ],
                name: "Jimmy Choo Clutch",
                price: 895,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25517290207&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Chloé Umhängetasche Faye Mini New Chain in grau",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/be01b7dc22cc7a4cbce896803ccc3259.jpg",
                    title: "Chloé Umhängetasche Faye Mini New Chain grau",
                  },
                ],
                name: "Chloé Umhängetasche",
                price: 950,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25658675083&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Versace Umhängetasche in schwarz",
                    src:
                      "https://images.buybags.de/fit-in/1000x1000/products/9bf94355b091baa359e65f2575cc7582.jpg",
                    title: "Versace Umhängetasche schwarz",
                  },
                ],
                name: "Versace Umhängetasche",
                price: 1090,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
